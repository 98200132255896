import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import ChartAnnotation from "chartjs-plugin-annotation";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignalFeed from "./components/SignalFeed";
import MaintenancePage from "./MaintenancePage";
import ChartPage from "./ChartPage";
import "./App.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartAnnotation
);

const App = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [performanceRecords, setPerformanceRecords] = useState([]);
  const [firstStratInfo, setFirstStratInfo] = useState({});
  //const [secondStratInfo, setSecondStratInfo] = useState({});
  const [isMaintenance, setIsMaintenance] = useState(
    process.env.REACT_APP_MAINTENANCE_MODE === "true"
  );
  const audioContextRef = useRef(null);

  useEffect(() => {
    const logVisitor = async () => {
      try {
        await fetch("https://chart2text.com/api/log-visiteur", {
          // Use absolute URL
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
      } catch (error) {
        console.error("Error logging visitor:", error);
      }
    };

    logVisitor();
  }, []);

  const playTone = (frequency = 440, duration = 500) => {
    if (!audioContextRef.current) return;

    const context = audioContextRef.current;
    const oscillator = context.createOscillator();
    const gainNode = context.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(context.destination);

    oscillator.type = "sine";
    oscillator.frequency.value = frequency;
    oscillator.start();

    // Set the gain to maximum
    gainNode.gain.setValueAtTime(1, context.currentTime); // Volume
    gainNode.gain.exponentialRampToValueAtTime(
      1,
      context.currentTime + duration / 1000
    );

    oscillator.stop(context.currentTime + duration / 1000);
  };

  const fetchData = async () => {
    if (isMaintenance) return;
    try {
      const performanceResponse = await fetch(`/api/signals`);
      const performanceData = await performanceResponse.json();
      console.log("Fetched Performance Records:", performanceData);
      if (performanceData.performanceRecords) {
        setPerformanceRecords(performanceData.performanceRecords);
        const lastRecord = performanceData.performanceRecords[0];
        const recordTimestamp = new Date(lastRecord.timestamp);
        const currentTime = new Date();
        const timeDifference = currentTime - recordTimestamp;
        console.log("sound..", lastRecord.unrealized_pnl);
        if (timeDifference < 60000) {
          if (lastRecord.confidence > 80) {
            playTone(16000, 100);
            playTone(8000, 100);
            playTone(6000, 100);
            playTone(100, 300);
          } else {
            playTone(900, 5);
          }
        }
      }
      const stratAResponse = await fetch(`/api/stratA`);
      const stratAData = await stratAResponse.json();
      console.log("Fetched Strat A Info:", stratAData);
      if (stratAData.tradesRecords) {
        setFirstStratInfo(stratAData.tradesRecords);
      }
      /*
      const stratBResponse = await fetch(`/api/stratB`);
      const stratBData = await stratBResponse.json();
      console.log('Fetched Strat B Info:', stratBData);
      if (stratBData.tradesRecords2) {
        setSecondStratInfo(stratBData.tradesRecords2);
      }
      */
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isMaintenance]);

  /*
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
      setRefreshKey(prevKey => prevKey + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);
  */

  useEffect(() => {
    const getMillisecondsUntilNextFetch = () => {
      const now = new Date();
      const nextFetch = new Date(now);
      nextFetch.setSeconds(12);
      nextFetch.setMilliseconds(0);
      if (now.getSeconds() >= 15) {
        nextFetch.setMinutes(now.getMinutes() + 1);
      }
      return nextFetch - now;
    };

    const startInterval = () => {
      fetchData();
      setRefreshKey((prevKey) => prevKey + 1);
      const interval = setInterval(() => {
        fetchData();
        setRefreshKey((prevKey) => prevKey + 1);
      }, 60000);
      return interval;
    };

    const timeout = setTimeout(() => {
      const interval = startInterval();
      return () => clearInterval(interval);
    }, getMillisecondsUntilNextFetch());

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleUserGesture = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }
    playTone(16000, 100);
    playTone(8000, 100);
    playTone(6000, 100);
    playTone(100, 300);
    console.log("sound activated");
  };

  if (isMaintenance) {
    return <MaintenancePage />;
  }

  const chartData = {
    labels: performanceRecords.map((record) => new Date(record.timestamp)),
    datasets: [
      {
        label: "Vision PNL",
        data: performanceRecords.map(
          (record) => record.unrealized_pnl + record.total_pnl
        ),
        borderColor: "lightgreen",
        fill: false,
        pointRadius: 0,
        borderWidth: 0.8,
        yAxisID: "y-axis-1",
      },
      {
        label: "SB PNL",
        data: performanceRecords.map(
          (record) => record.unrealized_pnl_2 + record.total_pnl_2
        ),
        borderColor: "cyan",
        fill: false,
        pointRadius: 0,
        borderWidth: 0.8,
        yAxisID: "y-axis-3",
        hidden: true,
      },
      /*
      {
        label: 'Confidence',
        data: performanceRecords
          .filter(record => record.confidence >= 62.00)
          .map(record => ({
            x: new Date(record.timestamp),
            y: record.confidence,
          })),
        pointBackgroundColor: performanceRecords.filter(record => record.confidence >= 62.00).map(record => record.position === 'LONG' ? 'green' : 'red'),
        pointBorderColor: performanceRecords.filter(record => record.confidence >= 62.00).map(record => record.position === 'LONG' ? 'green' : 'red'),
        fill: false,
        pointRadius: 6,
        borderWidth: 0,
        showLine: false, // Ensures only dots are shown
        yAxisID: 'y-axis-4',
      },
      */
      {
        label: "BTC Price",
        data: performanceRecords.map((record) => record.price),
        borderColor: "pink",
        fill: false,
        borderWidth: 0.8,
        pointRadius: 0,
        yAxisID: "y-axis-2",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
        },
        display: false, // Hide the x-axis labels and metrics
        ticks: {
          display: false, // Hide the x-axis ticks
        },
      },
      "y-axis-1": {
        type: "linear",
        position: "left",
        display: false, // Hide the y-axis labels and metrics
        ticks: {
          display: false, // Hide the y-axis ticks
        },
      },
      "y-axis-2": {
        type: "linear",
        position: "right",
        display: false, // Hide the y-axis labels and metrics
        ticks: {
          display: false, // Hide the y-axis ticks
        },
      },
      "y-axis-3": {
        type: "linear",
        position: "right",
        display: false, // Hide the y-axis labels and metrics
        ticks: {
          display: false, // Hide the y-axis ticks
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Value: ${context.raw}`;
          },
        },
      },
      annotation: {
        annotations: [],
      },
    },
    layout: {
      padding: {
        left: 40,
        right: 40,
        top: 30,
        bottom: 30,
      },
    },
  };

  return (
    <Router>
      <Routes>
        <Route path="/chart" element={<ChartPage />} />
        <Route
          path="/"
          element={
            <div key={refreshKey} className="App">
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  color: "white",
                  padding: "10px",
                  textAlign: "center",
                  zIndex: 1000,
                }}
              >
                Domain For Sale: oswebsitesforsale@gmail.com
              </div>
              <h1>[Chart-2-Text]</h1>
              <h1
                style={{ fontSize: "1em", color: "gray", fontWeight: "normal" }}
              >
                <a
                  href="https://github.com/bitcoinvsalts"
                  style={{ color: "#48adf1" }}
                >
                  https://github.com/bitcoinvsalts
                </a>
              </h1>
              <h4>
                <a href="mailto:herve76@gmail.com" style={{ color: "#ddeedd" }}>
                  ASSET FOR SALE
                </a>
              </h4>
              <div className="button-container">
                <button onClick={handleUserGesture}>Enable Sound</button>
              </div>
              <div className="disclaimer">
                <p>
                  Disclaimer: This site is for educational and research purposes
                  only and should not be considered as financial advice. The
                  information provided here is not intended to be a substitute
                  for professional financial advice. Trading cryptocurrencies
                  involves significant risk and you should always conduct your
                  own research and consult with a qualified financial advisor
                  before making any investment decisions.
                </p>
                <p>
                  Chart2Text is an innovative platform that automates chart
                  analysis using advanced GPT-4 technology. By auto-uploading
                  charts, it provides precise insights and trading signals based
                  on real-time data and technical indicators. Ideal for traders
                  seeking to enhance their strategies, Chart2Text leverages the
                  power of AI to interpret market trends and deliver actionable
                  recommendations, all presented in an easy-to-understand
                  format. Perfect for both novice and experienced traders, this
                  tool helps in making informed trading decisions with
                  confidence.
                </p>
              </div>
              <Line data={chartData} options={options} />
              <SignalFeed
                signals={performanceRecords}
                firstStratInfo={
                  firstStratInfo
                } /*secondStratInfo={secondStratInfo}*/
              />
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
